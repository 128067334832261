// istanbul ignore file
import { translate, translateFormatter } from '@manomano-internal/i18n/esm/placeholders';
export const arrows = translate({
  "titleLeft": "Previo",
  "titleRight": "Pr\xF3ximo"
});
export const price = translate({
  "vatExclLabel": "SIN IVA",
  "vatInclLabel": "CON IVA"
});
export const rating = {
  ariaLabel: (value: number) => translateFormatter<{
    value: number;
  }>("{value} de 5", undefined, undefined, "es").format({
    value
  }) as string,
  reviewsLabel: (value: number) => translateFormatter<{
    value: number;
  }>("de {value, plural, =1 {# opini\xF3n} other {# opiniones}}", undefined, undefined, "es").format({
    value
  }) as string
};
export const pagination = translate({
  "previousPageAriaLabel": "P\xE1gina anterior",
  "nextPageAriaLabel": "P\xE1gina siguiente"
});
export const counter = translate({
  "decrementAriaLabel": "Decrementar",
  "incrementAriaLabel": "Incrementar"
});
export const inputPhone = {
  countriesLabels: translate({
    "BE": "B\xE9lgica",
    "DE": "Alemania",
    "ES": "Espa\xF1a",
    "FR": "Francia",
    "GB": "Reino Unido",
    "IT": "Italia",
    "LU": "Luxemburgo",
    "CH": "Suiza"
  })
};